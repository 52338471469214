<template>
  <div class="container">
    <table-page dataName="whites"
                :tabList="tabList"
                ref="table"
                :search="search"
                :params="{activity_id:$route.query.id}"
                @on-success="fetchSuccess"
                :request="luckyWhiteList">
      <template slot="button">
        <FileUpload type="primary"
                    @on-success="$refs.table.fetch()"
                    buttonType="icon"
                    v-permission="['ADMIN']"
                    class="mr-15"
                    :params="{import_type:'lucky_log_whites_data',carry_data:{activity_id:$route.query.id}}">导入数据</FileUpload>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="downloadWhite">下载白名单模板</el-button>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="delNetOption(scope.row)"
                     v-permission="['ADMIN']">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileUpload from '@/components/FileUploadAsync'
import { luckyWhiteList, luckyDelWhite } from '@/api/market'
export default {
  name: 'LuckyWhite',
  data () {
    return {
      luckyWhiteList,
      qrcodeUrl: '',
      disabled: {},
      area: {},
      exportUrl: '',
      activity_id: '',
      search: [{
        type: 'input',
        name: '手机号码',
        placeholder: '请输入手机号码',
        key: 'tel',
        value: ''
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'isUse',
        label: '已使用'
      }, {
        value: 'notUse',
        label: '未使用'
      }]
    }
  },
  components: {
    TablePage,
    FileUpload
  },
  methods: {
    downloadWhite () {
      window.open(`${process.env.VUE_APP_BASE_URL}/data/lucky/%E6%8A%BD%E5%A5%96%E6%B4%BB%E5%8A%A8%E7%99%BD%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx`, '_blank')
    },
    setOption (scope) {
      this.$refs.setOption.showDialog(scope)
    },
    showQrcode (row) {
      this.qrcodeUrl = row.web_url
      this.$refs.qrcode.getQRCode()
    },
    delNetOption (row) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await luckyDelWhite({
          activity_id: this.$route.query.id,
          id: row.id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => { })
    },
    exportStoresData () {
      window.open(this.exportUrl, '_blank')
    },
    fetchSuccess (e) {
      this.exportUrl = e.customer_export_url
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
